import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_IS_SITE_PASSWORD_PROTECTED
  } from "./actionTypes";

  const initialState = {
    is_site_password_protected : []
  };

  const isSitePasswordProtected = persistReducer(
    { storage, key: "v726-demo1-is_site_password_protected", whitelist: ["is_site_password_protected"] },
    (state = initialState, action) => {
    switch (action.type) {
      case SET_IS_SITE_PASSWORD_PROTECTED:
        state = { ...state, is_site_password_protected: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });

  export default isSitePasswordProtected;
  