import React, { useState, useEffect} from 'react';
import MainScreen from './MainScreen';
import { Routes } from "./Routes";
import UserRoutes from "./UserRoutes";
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSettingsCall } from './services/adminSettingsService';
import { setAdminSettings } from "../store/adminSettings/actions";
import ScrollToTop from './pages/Components/ScrollToTop/ScrollToTop';
import { setLoggedInUser } from "../store/userLoggedIn/actions";
import { setSignupIncompleteUser } from "../store/signupIncompleteUser/actions";
import moment from 'moment';
import * as Common from "./General/common";

function RoutesMain(){

    const userRole = useSelector((store) => store.UserRole.userRole);
    const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);
    const signup_incomplete_user = useSelector(
      (store) => store.SignupIncompleteUser.signup_incomplete_user
    );
  
    const dispatch = useDispatch();
    const history = useHistory();

    // function which fetches the admin settings
    const getAdminSettings = async () => {
      let settingsData = {};
      try {
        const { data } = await getSettingsCall();
        //console.log("settingsResponse", data);
        if (data.success) {
          // success scenario
          if (data.data.length > 0) {
            for(let i = 0; i < data?.data?.length; i++){
                settingsData = {
                    ...settingsData,
                    [data?.data?.[i]?.key] : data?.data?.[i]?.value
                }
            }
          }else{
            
          }
        } else {
          
        }
      } catch {
        
      } finally {
        dispatch(setAdminSettings(settingsData));
      }
    };

    /* GET ADMIN SETTINGS FUNCTION ENDS */

    useEffect(() => {
        getAdminSettings();
    }, []);

    useEffect(() => {
      if(loggedInUser?.id > 0){
        if(moment.utc().isSameOrAfter(moment.utc(loggedInUser?.tokenExpiryTime))){
          dispatch(setLoggedInUser(null));
          dispatch(setSignupIncompleteUser(null));
          localStorage.clear();
          history.push("/");
          Common.showSuccessAlertAutoClose(
            "Please Login again.",
            "info",
            "Session Expired!",
            3000
          );
        }
      }
    });

    useEffect(() => {
      if(signup_incomplete_user?.id > 0){
        if(moment.utc().isSameOrAfter(moment.utc(signup_incomplete_user?.tokenExpiryTime))){
          dispatch(setLoggedInUser(null));
          dispatch(setSignupIncompleteUser(null));
          localStorage.clear();
          history.push("/");
          Common.showSuccessAlertAutoClose(
            "Please Login again.",
            "info",
            "Session Expired!",
            3000
          );
        }
      }
    });
 
    return(
        <>
            {
            <ScrollToTop>
                <UserRoutes />
             </ScrollToTop>

                // userRole === null ? 
                // <Switch>
                //   {
                //     <Redirect exact from="/" to="/main-screen" />
                //   }
                //   <Route path="/main-screen" component={MainScreen} />
                // </Switch>
                // :
                // userRole === "superadmin" ? 
                // <Routes />
                // :
                // userRole === "user" ?
                // <UserRoutes />
                // :
                // <></>
            }
        </>
    )
}

export default RoutesMain;