import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_USER_ROLE
  } from "./actionTypes";

  const initialState = {
    userRole : "user"
  };

  const userRole = persistReducer(
    { storage, key: "v726-demo1-userRole", whitelist: ["userRole"] },
    (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_ROLE:
        state = { ...state, userRole: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });

  export default userRole;
  