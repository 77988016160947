import * as API from "../../General/api";

// vendor forum calls starts
export function getVendorForumCall() {
    return API.getRequest(API.apiUrls.user.vendorForum.getVendorForum);
}
export function postVendorForumCall(values) {
    return API.postWithFilesRequest(API.apiUrls.user.vendorForum.postVendorForum, values);
}
export function postaddForumLikeCall(values) {
    return API.postWithFilesRequest(API.apiUrls.user.vendorForum.addForumLike, values);
}
export function postremoveForumLikeCall(id){
    return API.postRequest(`${API.apiUrls.user.vendorForum.removeForumLike}/${id}`)
}
export function getVendorDetailsById(id){
    return API.getRequest(`${API.apiUrls.user.vendorForum.getVendorForumById}/${id}`)
}

export function postVendorForumCommentsCall(values) {
    return API.postWithFilesRequest(API.apiUrls.user.vendorForum.postVendorForumComment, values);
}
// vendor forum calls ends