import {
    SET_ADMIN_SETTINGS
  } from "./actionTypes";

  const initialState = {
    admin_settings : {}
  };

  const adminSettings = (state = initialState, action) => {
    switch (action.type) {
      case SET_ADMIN_SETTINGS:
        state = { ...state, admin_settings: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };

  export default adminSettings;
  