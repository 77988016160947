import {
    SET_HAS_ACCESS_TO_SITE
  } from "./actionTypes";

 
  export const setHasAccessToSite = val => {
    return {
      type: SET_HAS_ACCESS_TO_SITE,
      payload: val,
    };
  };