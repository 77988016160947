import React, { Suspense, lazy } from 'react';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponent from '../../../pages/Components/ErrorBoundaryFallbackComponent/ErrorFallbackComponent';
import ContactUs from '../../../pages/userPages/footerPages/ContactUs';



const EventsPage = lazy(() =>
  import("../../../pages/userPages/EventsPages")
);

const SignUp = lazy(() =>
  import("./SignUp")
);
const Login = lazy(() =>
  import("./Login")
);
const SignUpForm = lazy(() =>
  import("./SignUpForm")
);
const WeddingPage = lazy(() =>
  import("../../../pages/userPages/wedding/WeddingPage")
);
const WeddingRituals = lazy(() =>
  import("../../../pages/userPages/wedding/WeddingRituals")
);
const PlanningMain = lazy(() =>
  import("../../../pages/userPages/planning/PlanningMain")
);
const SeatingChart = lazy(() =>
  import("../../../pages/userPages/planning/SeatingChart")
);
const BudgetTool = lazy(() =>
  import("../../../pages/userPages/planning/BudgetTool")
);
const GetStarted = lazy(() =>
  import("./GetStarted")
);
const VendorProfile = lazy(() =>
  import("../../../pages/userPages/wedding/VendorProfile")
);
const SignUpCouple = lazy(() =>
  import("./SignUpCouple")
);
const SignUpVendor = lazy(() =>
  import("./SignUpVendor")
);
const SignUpVendorCustom = lazy(() =>
  import("./SignUpVendorCustom")
);
const VendorChoosePlan = lazy(() =>
  import("./VendorChoosePlan")
);

const VendorChoosePlanCustom = lazy(() =>
  import("./VendorChoosePlanCustom")
);
const VendorPlanCustomization = lazy(() =>
  import("./VendorPlanCustomization")
);
const VendorPurchaseSubscription = lazy(() =>
  import("./VendorPurchaseSubscription")
);

const VendorPurchaseSubscriptionCustom = lazy(() =>
  import("./VendorPurchaseSubscriptionCustom")
);
const VendorPurchaseSuccess = lazy(() =>
  import("./VendorPurchaseSuccess")
);
const VendorAccountCreate = lazy(() =>
  import("./VendorAccountCreate")
);
const EngagementPage = lazy(() =>
  import("../../../pages/userPages/engagement/EngagementPage")
);
const ColorInspiration = lazy(() =>
  import("../../../pages/userPages/planning/ColorInspiration")
);
const HashtagGenerator = lazy(() =>
  import("../../../pages/userPages/planning/HashtagGenerator")
);
const AdvertiseWithUs = lazy(() =>
  import("../../../pages/userPages/footerPages/AdvertiseWithUs")
);
const AboutUs = lazy(() =>
  import("../../../pages/userPages/footerPages/AboutUs")
);
const FAQ = lazy(() =>
  import("../../../pages/userPages/footerPages/FAQ")
);
const PrivacyPolicy = lazy(() =>
  import("../../../pages/userPages/footerPages/privacyPolicy")
);
const TermsAndConditions = lazy(() =>
  import("../../../pages/userPages/footerPages/TermsAndConditions")
);
const VendorSupport = lazy(() =>
  import("../../../pages/userPages/footerPages/VendorSupport")
);
const HoneymoonPage = lazy(() =>
  import("../../../pages/userPages/honeymoon/HoneymoonPage")
);
const HoneymoonSections = lazy(() =>
  import("../../../pages/userPages/honeymoon/HoneymoonSections")
);
const InspoBlog = lazy(() =>
  import("../../../pages/userPages/inspo/InspoBlog")
);
const BlogDetail = lazy(() =>
  import("../../../pages/userPages/inspo/BlogDetail")
);
const CheckList = lazy(() =>
  import("../../../pages/userPages/planning/CheckList")
);
const Sweepstakes = lazy(() =>
  import("../../../pages/userPages/sweepstakes/SweepstakesForm")
);
const WebsiteCatalogMain = lazy(() =>
  import("../../../pages/userPages/website/WebsiteCatalogMain")
);
const CreateWebsiteAllDesigns = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/CreateWebsiteAllDesigns")
);
const SelectedTheme = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/SelectedTheme")
);
const SweepstakesMainPage = lazy(() =>
  import("../../../pages/userPages/sweepstakes/SweepstakesMainPage")
);
const SweepstakesSuccess = lazy(() =>
  import("../../../pages/userPages/sweepstakes/SweepstakesSuccess")
);
const SiteHomePreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteHomePreviewPage")
);
const SiteSchedulePreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteSchedulePreviewPage")
);
const SiteTravelPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteTravelPreviewPage")
);
const SiteWeddingPartyPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteWeddingPartyPreviewPage")
);
const SiteThingsToDoPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteThingsToDoPreviewPage")
);
const SiteFAQsPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteFAQsPreviewPage")
);
const SiteUsPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteUsPreviewPage")
);
const SiteRsvpPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteRsvpPreviewPage")
);
const SiteRegistryPreviewPage = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/SiteRegistryPreviewPage")
);
const NotFound404Page = lazy(() =>
  import("../../../pages/404/NotFound404Page")
);
const EnterEmail = lazy(() =>
  import("./EnterEmail")
);
const RecoverPassword = lazy(() =>
  import("./RecoverPassword")
);
const Site404Page = lazy(() =>
  import("../../../pages/userPages/website/createWebsite/previewPages/Site404Page")
);
const ComingSoon = lazy(() =>
  import("../../../pages/Components/ComingSoon")
);
const RegistryMain = lazy(() =>
  import("../../../pages/userPages/registry/RegistryMain")
);
const SubscriptionPlans = lazy(() =>
  import("../../../pages/SubscriptionPlans")
);
const SubscriptionViewPlan = lazy(() =>
  import("../../../pages/SubscriptionViewPlan")
);

const HoneymoonQuizPage = lazy(() => 
import("../../../pages/userPages/honeymoon/honeymoonQuiz/HoneymoonQuizPage")
);

const HoneymoonQuizResult = lazy(() =>
import("../../../pages/userPages/honeymoon/honeymoonQuiz/HoneymoonQuizResult")
);


const AuthPage = () => {

    const errorHandler = (error, errorInfo) => {
     //  console.log({error, errorInfo});
    }

    return(
        <div className='main-content-area-user'>
          <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={errorHandler}>
            <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Route path="/" component={WeddingPage} exact />
                <Route path="/signin" component={SignUp} exact />
                <Route path="/enter-email" component={EnterEmail} exact />
                <Route path="/reset-password" component={RecoverPassword} exact />
                <Route path="/login" component={Login} exact />
                <Route path="/login-guestlist" component={() => <Login isGuestList={true} />} exact />
                <Route path="/login-website" component={() => <Login isWebsite={true} />} exact />
                <Route path="/signupform" component={SignUpForm} exact />
                <Route path="/signup-couple" component={SignUpCouple} exact />
                <Route path="/signup-vendor" component={SignUpVendor} exact />
                <Route path="/signup-vendor-custom" component={SignUpVendorCustom} exact />
                <Route path="/vendor-choose-plan" component={VendorChoosePlan} exact />
                <Route path="/vendor-choose-plan-custom" component={VendorChoosePlanCustom} exact />
                <Route path="/vendor-plan-customization/:planId" component={VendorPlanCustomization} exact />
                <Route path="/vendor-purchase-subscription/:planId" component={VendorPurchaseSubscription} exact />
                <Route path="/vendor-purchase-subscription-custom/:planId" component={VendorPurchaseSubscriptionCustom} exact />
                <Route path="/vendor-purchase-success" component={VendorPurchaseSuccess} exact />
                <Route path="/vendor-account-create" component={VendorAccountCreate} exact />
                <Route path="/wedding" component={WeddingPage} exact />
                <Route path="/wedding/:categoryName" component={WeddingRituals} exact />
                
                <Route path="/vendor-profile/:vendorId" component={VendorProfile} exact />
                
                <Route path="/events" component={EventsPage} exact />

                
                
                <Route path="/subscription-plans" component={SubscriptionPlans} exact />
                <Route path="/subscription-plan/:planId" component={SubscriptionViewPlan} exact />

                

                <Route path="/engagement" component={EngagementPage} exact />
                <Route path="/engagement/:categoryName" component={EngagementPage} exact />


                <Route path="/honeymoon" component={HoneymoonPage} exact />
                <Route path="/honeymoon-quiz" component={HoneymoonQuizPage} exact />
                <Route path="/honeymoon-result/:answer" component={HoneymoonQuizResult} exact />
                <Route path="/honeymoon/:categoryName" component={HoneymoonSections} exact />


                <Route path="/advertise-with-us" component={AdvertiseWithUs} exact />
                <Route path="/about-us" component={AboutUs} exact />
                <Route path="/contact-us" component={ContactUs} exact />
                <Route path="/faq" component={FAQ} exact />
                <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                <Route path="/terms-and-conditions" component={TermsAndConditions} exact />
                <Route path="/vendor-support" component={VendorSupport} exact />

                <Route path="/registry-main" component={RegistryMain} exact />

                <Route path="/planning" component={PlanningMain} exact />
                {/* <Route path="/seating-chart" component={SeatingChart} exact /> */}
                <Route path="/color-inspiration" component={ColorInspiration} exact />
                {/* <Route path="/hashtag-generator" component={HashtagGenerator} exact /> */}
                <Route path="/budget-tool" component={BudgetTool} exact />

                <Route path="/inspo-blog" component={InspoBlog} exact />
                <Route path="/inspo-blog/:slug" component={BlogDetail} exact />

                <Route path="/inspo-blog/category/:categoryName" component={InspoBlog} exact />

                <Route path="/sweepstakes" component={SweepstakesMainPage} exact />
                <Route path="/sweepstakes/form" component={Sweepstakes} exact />
                <Route path="/sweepstakes/success" component={SweepstakesSuccess} exact />

                <Route path="/website-catalog-main" component={WebsiteCatalogMain} exact />
                <Route path="/create-website/all-designs" component={CreateWebsiteAllDesigns} exact />
                <Route path="/create-website/selected-theme/:themeId" component={SelectedTheme} exact />

                <Route path="/404" component={NotFound404Page} exact />

                <Route path="/website/preview/:siteSlug/home" component={SiteHomePreviewPage} exact />
                <Route path="/website/preview/:siteSlug/schedule" component={SiteSchedulePreviewPage} exact />
                <Route path="/website/preview/:siteSlug/travel" component={SiteTravelPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/registry" component={SiteRegistryPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/wedding-party" component={SiteWeddingPartyPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/things-to-do" component={SiteThingsToDoPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/faqs" component={SiteFAQsPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/us" component={SiteUsPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/rsvp" component={SiteRsvpPreviewPage} exact />
                <Route path="/website/preview/:siteSlug/404" component={Site404Page} exact />

                <Route path="/coming-soon" component={ComingSoon} />

                <Redirect from="/" exact={true} to="/" />
                <Redirect to="/" />
            </Switch>
            </Suspense>
            </ErrorBoundary>
        </div>
    )
}

export default AuthPage;