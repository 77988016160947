import * as API from "../General/api";

// blog calls starts
export function getAdminBlogPostsCall() {
    return API.getRequest(API.apiUrls.adminBlogs.getAdminBlogPosts);
}
export function getBlogCategoriesCall() {
    return API.getRequest(API.apiUrls.adminBlogs.getBlogCategories);
}
export function insertAdminBlogPostCall(blogPost) {
    return API.postWithFilesRequest(API.apiUrls.adminBlogs.insertAdminBlogPost, blogPost);
}
export function updateAdminBlogPostCall(blogPostId, blogPost) {
    return API.postWithFilesRequest(`${API.apiUrls.adminBlogs.updateAdminBlogPost}/${blogPostId}?_method=PUT`, blogPost);
}
export function deleteAdminBlogPostCall(blogPostId) {
    return API.deleteRequest(API.apiUrls.adminBlogs.deleteAdminBlogPost, blogPostId);
}
export function getAdminBlogPostDetailsByIdCall(id) {
    return API.getRequest(`${API.apiUrls.adminBlogs.getAdminBlogPostDetailsById}/${id}`);
}
export function getPublicBlogCategoriesCall() {
    return API.getRequest(API.apiUrls.adminBlogs.getPublicBlogCategories);
}
// blog calls ends
