import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_LOGGED_IN_USER,
  } from "./actionTypes";

  const initialState = {
    loggedInUser: null,
  };

  const userLoggedIn = persistReducer(
    { storage, key: "v726-demo1-loggedInUser", whitelist: ["loggedInUser"] },
    (state = initialState, action) => {
    switch (action.type) {
      case SET_LOGGED_IN_USER:
        state = { ...state, loggedInUser: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });

  export default userLoggedIn;
  