import {
    SET_USER_ROLE
  } from "./actionTypes";

 
  export const setUserRole = userRole => {
    return {
      type: SET_USER_ROLE,
      payload: userRole,
    };
  };