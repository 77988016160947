import React from 'react';
import { Card, Form } from 'react-bootstrap';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ApiBaseUrl, apiUrls } from '../../../constants/endpoints';
import axios from "axios"
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import { contactUs } from '../../../services/contactService';

function ContactUs(){

    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        userType: Yup.string()
        .required("User Type is Required"),
        firstName: Yup.string()
        .required("First Name is Required"),
        lastName: Yup.string()
        .required("Last Name is Required"),
        email: Yup.string()
        .email("Invalid Email")
        .required("Email is Required"),
        phone: Yup.number("Enter Numbers")
        .required("Phone Number is Required"),
        message: Yup.string()
        .required("Message is Required"),
    })

    const formik = useFormik({
        initialValues: {
            userType: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    user_type: values.userType,
                    email: values.email,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    phone: values.phone,
                    message: values.message
                }
                const response = await contactUs(payload)
                if(response.data.success == true){
                    Swal.fire({
                        icon: "success",
                        title: "Thank You",
                        showConfirmButton: false,
                        text: "For contacting us, We'll be in touch soon."
                    })
                    formik.handleReset()
                    setLoading(false)
                }else{
                    Swal.fire({
                        icon: "error",
                        text: response.data.message
                    })
                    setLoading(false)
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    text: error.message
                })
                setLoading(false)
            }
        }
    })

    return(
        <>
            <div className='wedding-main-container-cus px-6 py-20'>
                <h1 className="footer-pages-heading text-center">Contact Us</h1>
                <h6 className="w-100 p-5 mb-5 text-center">We would love to hear from you!</h6>
                <div className='row mb-8 p-7 footer-about-page-card mx-0 d-flex justify-content-center'>
                    <Form style={{width: "75%"}}>

                    <div className="form-group fv-plugins-icon-container">
                        <label className='form-label form_label_cus' >Who you are?*</label>
                        <br />
                        <div style={{gap: 100}} className='d-flex'>

                        <div
                        onClick={() => {
                            formik.setFieldValue("userType", "vendor")
                        }}
                        style={{gap: 5, cursor: "pointer"}} className='d-flex'>
                        <input
                        style={{cursor: "pinter"}}
                        id='vendor'
                        type="radio"
                        name="userType"
                        checked={formik.values.userType == "vendor"}
                        />
                        <label style={{cursor: "pinter", marginTop: 5}} htmlFor='vendor' >Vendor</label>
                        
                        </div>
                        
                        <div
                        onClick={() => {
                            formik.setFieldValue("userType", "couple")
                        }}
                        style={{gap: 5, cursor: "pointer"}} className='d-flex'>
                        <input
                        style={{cursor: "pinter"}}
                        id='couple'
                        type="radio"
                        name="userType"
                        checked={formik.values.userType == "couple"}
                        />
                        <label style={{cursor: "pinter", marginTop: 5}} htmlFor='couple' >Couple</label>
                        </div>
                        <br />

                        

                        </div>
                        {
                            formik.touched.userType && formik.errors.userType &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.userType}</div>
                            </div>
                        }
                    </div>    

                    <div className="form-group fv-plugins-icon-container">
                        <label className='form-label form_label_cus' >First Name*</label>
                        <input
                        placeholder='John'
                        type={"text"}
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        className="form-control form-control-cus-radius-0"
                        />
                        {
                            formik.touched.firstName && formik.errors.firstName &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.firstName}</div>
                            </div>
                        }
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                        <label className='form-label form_label_cus' >Last Name*</label>
                        <input
                        placeholder='Smith'
                        type={"text"}
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        className="form-control form-control-cus-radius-0"
                        />
                        {
                            formik.touched.lastName && formik.errors.lastName &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.lastName}</div>
                            </div>
                        }
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                        <label className='form-label form_label_cus' >Email*</label>
                        <input
                        placeholder='example@example.com'
                        type={"email"}
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className="form-control form-control-cus-radius-0"
                        />
                        {
                            formik.touched.email && formik.errors.email &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.email}</div>
                            </div>
                        }
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                        <label className='form-label form_label_cus' >Phone*</label>
                        <input
                        placeholder='Enter Phone Number'
                        type={"number"}
                        name="phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        className="form-control form-control-cus-radius-0"
                        />
                        {
                            formik.touched.phone && formik.errors.phone &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.phone}</div>
                            </div>
                        }
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                        <label className='form-label form_label_cus' >Message*</label>
                        <textarea
                        placeholder='Enter Your Message Here ...'
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        className="form-control form-control-cus-radius-0"
                        />
                        {
                            formik.touched.message && formik.errors.message &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.message}</div>
                            </div>
                        }
                    </div>

                    <div className="form-group">
                        <button type="button"
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                         className="btn btn-primary-cus fw-700 my-3 w-100 py-2 rounded-0"
                         disabled={loading}
                         >
                            {
                                loading ? 
                                <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                                </Spinner>
                                :
                                <span>SUBMIT</span>
                            }
                            
                        </button>
                    </div>
                    </Form>
                 </div>
            </div> 
        </>
    )
}

export default ContactUs;