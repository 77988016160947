import * as API from "../../General/api";

// vendor sign up process calls starts
export function signUpVendorCall(vendorData) {
    return API.postRequest(API.apiUrls.user.vendorSignupProcess.signup, vendorData);
}
export function getGlobalVendorEventsCall() {
    return API.getRequest(API.apiUrls.user.vendorSignupProcess.getGlobalVendorEvents);
}
export function getGlobalVendorEventCategoriesCall(id) {
    return API.getRequest(`${API.apiUrls.user.vendorSignupProcess.getGlobalVendorEventCategories}/${id}`);
}
export function getGlobalCompanyTypesCall() {
    return API.getRequest(API.apiUrls.user.vendorSignupProcess.getGlobalCompanyTypes);
}
export function getGlobalEventFiltersCall(slug) {
    return API.getRequest(`${API.apiUrls.user.vendorSignupProcess.getGlobalEventFilters}/${slug}`);
}
export function getGlobalVendorCategoryServicesCall(slug) {
    return API.getRequest(`${API.apiUrls.user.vendorSignupProcess.getGlobalVendorCategoryServices}/${slug}`);
}
export function setupProfileCall(userData) {
    return API.postWithFilesRequest(API.apiUrls.user.vendorSignupProcess.setupProfile, userData);
}
// vendor sign up process calls ends