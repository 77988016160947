import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import UserRole from "../store/userRole/reducer";
import UserLoggedIn from "../store/userLoggedIn/reducer";
import SignupIncompleteUser from "../store/signupIncompleteUser/reducer";
import HowToDecideClicked from "../store/howToDecideClicked/reducer";
import HoneymoonLocationSelected from "../store/honeymoonLocationSelected/reducer";
import IsSitePasswordProtected from "../store/isSitePasswordProtected/reducer";
import HasAccessToSite from "../store/hasAccessToSite/reducer";
import ShowMenu from "../store/showMenu/reducer";
import AdminSettings from "../store/adminSettings/reducer";
import DashboardColor from "../store/dashboardColor/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  UserRole,
  UserLoggedIn,
  HowToDecideClicked,
  SignupIncompleteUser,
  HoneymoonLocationSelected,
  IsSitePasswordProtected,
  HasAccessToSite,
  ShowMenu,
  AdminSettings,
  DashboardColor
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
