import React from 'react';
import { Link } from "react-router-dom";

function Footer(){
    return(
        <>
        <div className='footer_cus'>
        <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <Link to="/">
              <img src={"/media/svgs/logo_3.svg"} alt="Wedding Match" width="200px" height="70px"></img>
            </Link>

          </div>
          <div className="col-lg-3 col-md-3">
            <h5 className="footer-menu-title">WEDDING MATCH</h5>
            <ul className="list-style-none list-unstyled">
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/">Home</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/subscription-plans">Subscription Plans</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/terms-and-conditions">Term and conditions</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3">
          {/* <h5 className="font-size-h5 text_dark mb-6">CATEGORY</h5> */}
            <ul className="list-style-none list-unstyled">
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/faq">FAQ</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/advertise-with-us">Advertise with Us</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/about-us">About Us</Link>
              </li>
              <li className="my-1 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="/vendor-support">Vendor Support</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3">
              <h5 className="font-size-h5 text_dark">Follow us on</h5> 
              <ul className="list-style-none list-unstyled d-flex">
                <li className="my-1 nav_item_cus">
                  <a className="text_dark font-weight-bold text-decoration-none" href="https://www.facebook.com/people/WeddingMatch/61552234564079">
                    <img src={"/media/icons/facebook.png"} alt="Facebook"  width="30px" height="30px" />
                  </a>
                </li>
                <li className="my-1 nav_item_cus">
                  <a className="text_dark font-weight-bold text-decoration-none" href="https://www.instagram.com/the_weddingmatch">
                    <img src={"/media/icons/Insta.png"} alt="Instagram"  width="30px" height="30px" />
                  </a>
                </li>
                  {/* <li className="my-1 nav_item_cus">
                  <a className="text_dark font-weight-bold text-decoration-none" href="https://www.pinterest.com/The_WeddingMatch">
                    <img src={"/media/icons/pin.png"} alt="Pinterest"   width="30px" height="30px"/>
                  </a>
                </li>*/}
              </ul>
            </div>
          {/* <div className="col-md-3">
          <h5 className="font-size-h5 text_dark mb-6">CATEGORY</h5>
            <ul className="list-style-none list-unstyled">
              <li className="m-2 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="#">TBA</Link>
              </li>
              <li className="m-2 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="#">TBA</Link>
              </li>
              <li className="m-2 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="#">TBA</Link>
              </li>
              <li className="m-2 nav_item_cus">
                <Link className="text_dark font-weight-bold text-decoration-none" to="#">TBA</Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      </div>
        </>
    )
}

export default Footer;