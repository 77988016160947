import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_SIGNUP_INCOMPLETE_USER,
  } from "./actionTypes";

  const initialState = {
    signup_incomplete_user: null,
  };

  const signupIncompleteUser = persistReducer(
    { storage, key: "v726-demo1-signup_incomplete_user", whitelist: ["signup_incomplete_user"] },
    (state = initialState, action) => {
    switch (action.type) {
      case SET_SIGNUP_INCOMPLETE_USER:
        state = { ...state, signup_incomplete_user: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });

  export default signupIncompleteUser;
  