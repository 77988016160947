import {
    SET_SHOW_MENU
  } from "./actionTypes";

  const initialState = {
    show_menu : false
  };

  const showMenu = (state = initialState, action) => {
    switch (action.type) {
      case SET_SHOW_MENU:
        state = { ...state, show_menu: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };

  export default showMenu;
  