/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { AiOutlineDashboard } from "react-icons/ai";
import {
  FaBeer,
  FaAlignLeft,
  FaUserFriends,
  FaUsers,
  FaRegAddressBook,
  FaFileInvoiceDollar,
  FaEye,
  FaPen,
  FaTimes,
  FaBlog
} from "react-icons/fa";
import { FiSettings } from 'react-icons/fi';
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <AiOutlineDashboard />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
       
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Customers</h4>
          
        </li>
        {/* end:: section */}

        <li
          className={`menu-item ${getMenuItemActive("/customers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className="svg-icon menu-icon">
              <FaAlignLeft />
            </span>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li>
      

    
        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Vendors</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        <li
          className={`menu-item ${getMenuItemActive("/vendors", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/vendors">
            <span className="svg-icon menu-icon">
              <FaUserFriends />
            </span>
            <span className="menu-text">Vendors</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/vendorcategory", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/vendorcategory">
            <span className="svg-icon menu-icon">
              <FaUsers />
            </span>
            <span className="menu-text">Vendors Category</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/subscriptionplans", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptionplans">
            <span className="svg-icon menu-icon">
              <FaRegAddressBook />
            </span>
            <span className="menu-text">Vendors Subscription plans</span>
          </NavLink>
        </li>
         {/* end:: section */}
         {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Payment Detail</h4>
          
        </li> */}
       

        {/* <li
          className={`menu-item ${getMenuItemActive("/paymentmethod", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/paymentmethod">
            <span className="svg-icon menu-icon">
              <FaFileInvoiceDollar />
            </span>
            <span className="menu-text">Payments Method</span>
          </NavLink>
        </li> */}
        {/* end:: section */}
         {/* begin::section */}
         <li className="menu-section ">
          <h4 className="menu-text">Blog</h4>
          
        </li>
       

        <li
          className={`menu-item ${getMenuItemActive("/blog", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/blog">
            <span className="svg-icon menu-icon">
              <FaBlog />
            </span>
            <span className="menu-text">Blogs</span>
          </NavLink>
        </li>
        {/* end:: section */}

         {/* begin::section */}
         <li className="menu-section ">
          <h4 className="menu-text">Settings</h4>
          
        </li>
       

        <li
          className={`menu-item ${getMenuItemActive("/settings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/settings">
            <span className="svg-icon menu-icon">
              <FiSettings />
            </span>
            <span className="menu-text">Settings</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
