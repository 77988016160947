import React from 'react';
import somethingWentWrongImage from '../../../../imgaes/something-went-wrong.jpg';

function ErrorFallbackComponent(){
    return(
        <a href='/' className='p-5 d-flex flex-column align-items-center justify-content-center'>
            <img src={somethingWentWrongImage} width="270px"/>
            <h2 className='text-uppercase font-weight-light mt-5 p-2 text-center'>Something went wrong!</h2>
            <button className='bg-primary p-5 m-5 rounded border-0 text-dark'>Back to Home Page</button>
        </a>
    )
}

export default ErrorFallbackComponent;