import {
    SET_HOW_TO_DECIDE_CLICKED
  } from "./actionTypes";

 
  export const setHowToDecideClicked = val => {
    return {
      type: SET_HOW_TO_DECIDE_CLICKED,
      payload: val,
    };
  };