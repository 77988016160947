import {
    SET_HONEYMOON_LOCATION_SELECTED
  } from "./actionTypes";

  const initialState = {
    honeymoon_location_selected : null
  };

  const honeymoonLocationSelected = (state = initialState, action) => {
    switch (action.type) {
      case SET_HONEYMOON_LOCATION_SELECTED:
        state = { ...state, honeymoon_location_selected: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };

  export default honeymoonLocationSelected;
  