import {
    SET_HOW_TO_DECIDE_CLICKED
  } from "./actionTypes";

  const initialState = {
    decideButtonClicked : false
  };

  const howToDecideClicked = (state = initialState, action) => {
    switch (action.type) {
      case SET_HOW_TO_DECIDE_CLICKED:
        state = { ...state, decideButtonClicked: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };

  export default howToDecideClicked;
  