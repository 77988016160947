import React from 'react';
import { Skeleton } from 'antd';

function EngagementMenuSkeletonLoader(){
    return(
        <>
            <div className='w-100 row mx-auto p-5'>
                {
                    new Array(5).fill().map((x) => {
                        return(
                            <Skeleton.Input active={true} block className='col-md-6 my-2' />
                        )
                    })
                }
            </div>
        </>
    )
}

export default EngagementMenuSkeletonLoader;