import {
    SET_SHOW_MENU
  } from "./actionTypes";

 
  export const setShowMenu = val => {
    return {
      type: SET_SHOW_MENU,
      payload: val,
    };
  };