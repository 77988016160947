import React, { useEffect } from 'react';
import UserBasePage from "./UserBasePage";
import AuthPageUser from './modules/Auth/userAuthPages/AuthPage';
import { CustomLoader } from './pages/Components/customLoader';
import { useState } from 'react';
import Header from './pages/Components/Header';
import SmallScreenHeader from './pages/Components/SmallScreenHeader';
import Footer from './pages/Components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { setHasAccessToSite } from '../store/hasAccessToSite/actions';
import moment from 'moment';
import { setIsSitePasswordProtected } from '../store/isSitePasswordProtected/actions';

function UserRoutes() {

  const dispatch = useDispatch();

  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const isLoggedIn = useSelector((store) => store.User.isLoggedIn);
  const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);
  const [isLoading, setIsLoading] = useState(false);

  const has_access_to_site = useSelector((store) => store.HasAccessToSite.has_access_to_site);

  useEffect(() => {
    if(has_access_to_site?.tokenExpiryTime !== null){
      if(moment.utc().isSameOrAfter(moment.utc(has_access_to_site?.tokenExpiryTime))){
        setHasAccessToSite({
          tokenExpiryTime: null,
          pages: []
        });
      }
    }
  });

  return (
    <div className='app-container'>
      {
        isLoading ? 
        <div className='vh-100 d-flex justify-content-center align-items-center'>
          <CustomLoader />
        </div>
        :
        (loggedInUser !== null && loggedInUser?.role === "superadmin") ?
        <Layout>
          <BasePage />
        </Layout>
        :
        <div>
          <Header />
          {/* Header for large screen */}
          {/* <div className='user-header-lg'>
            <Header />
          </div> */}
          {/* Header for medium and small screens */}
          {/* <div className='user-header-sm'>
            <SmallScreenHeader />
          </div> */}
            {
              loggedInUser === null &&
              <AuthPageUser />
            }
            {
              loggedInUser !== null && 
              <UserBasePage />
            }
          <Footer />
        </div>
      }
    </div>
  );
}

export default UserRoutes;
