import React from 'react';
import { Empty } from 'antd';

function DataNotFoundEmpty({
    description
}){
    return(
        <>
        <Empty
          description={
            <span>
              {description}
            </span>
          }
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
        </Empty>
        </>
    )
}

export default DataNotFoundEmpty;