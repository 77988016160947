import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { setUserRole } from "../../../store/userRole/actions";
import { setLoggedInUser } from "../../../store/userLoggedIn/actions";
import { useDispatch, useSelector } from "react-redux";
import { setHowToDecideClicked } from "../../../store/howToDecideClicked/actions";
import { toast } from "../../General/common";
import { getGlobalEventFiltersCall } from "../../services/user-services/vendorSignUpService";
import { CustomLoader } from "./customLoader";
import { getHoneymoonLocationsCall } from "../../services/user-services/honeymoonLocationsService";
import { setHoneymoonLocationSelected } from "../../../store/honeymoonLocationSelected/actions";
import { setSignupIncompleteUser } from "../../../store/signupIncompleteUser/actions";
import { getBlogCategoriesCall, getPublicBlogCategoriesCall } from "../../services/adminBlogService";
import MenuSkeletonLoader from "./SkeletonLoaders/MenuSkeletonLoader";
import EngagementMenuSkeletonLoader from "./SkeletonLoaders/EngagementMenuSkeletonLoader";
import DataNotFoundEmpty from "./DataNotFound/DataNotFoundEmpty";
import { Dropdown } from 'react-bootstrap';
import { FaBars, FaRegTimesCircle } from "react-icons/fa";

function Header() {
  const [profileImage, setProfileImage] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPlanningDropdown, setShowPlanningDropdown] = useState(false);
  const [showEngagementDropdown, setShowEngagementDropdown] = useState(false);
  const [showWeddingDropdown, setShowWeddingDropdown] = useState(false);
  const [showHoneyMoonDropdown, setShowHoneyMoonDropdown] = useState(false);
  const [showInspoDropdown, setShowInspoDropdown] = useState(false);
  const [showTopMenu, setShowTopMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);
  const signup_incomplete_user = useSelector(
    (store) => store.SignupIncompleteUser.signup_incomplete_user
  );

  const admin_settings = useSelector((store) => store.AdminSettings.admin_settings);

  const [weddingFiltersLoading, setWeddingFiltersLoading] = useState(false);
  const [weddingFiltersList, setWeddingFiltersList] = useState([]);

  const [engagementFiltersLoading, setEngagementFiltersLoading] = useState(
    false
  );
  const [engagementFiltersList, setEngagementFiltersList] = useState([]);

  const [honeymoonLocationsLoading, setHoneymoonLocationsLoading] = useState(
    false
  );
  const [honeymoonLocationsList, setHoneymoonLocationsList] = useState([]);

  const [inspoCategoriesLoading, setInspoCategoriesLoading] = useState(
    false
  );
  const [inspoCategoriesList, setInspoCategoriesList] = useState([]);

  /* GET inspo categories FUNCTION STARTS */

  // function which fetches the inspo categories
  const getInspoCategories = async () => {
    setInspoCategoriesLoading(true);
    try {
      const { data } = await getPublicBlogCategoriesCall();
      //console.log("InspoCategoriesResponse", data);
      if (data.success) {
        // success scenario
        if (data?.data?.length > 0) {
          setInspoCategoriesList(data.data);
        } else {
          setInspoCategoriesList([]);
        }
      } else {
        setInspoCategoriesList([]);
      }
    } catch {
      setInspoCategoriesList([]);
    } finally {
      setInspoCategoriesLoading(false);
    }
  };

  /* GET inspo categories FUNCTION ENDS */


  /* GET wedding filters FUNCTION STARTS */

  // function which fetches the wedding filters
  const getWeddingFilters = async () => {
    setWeddingFiltersLoading(true);
    try {
      const { data } = await getGlobalEventFiltersCall("wedding");
      //console.log("WeddingFiltersResponse", data);
      if (data.success) {
        // success scenario
        if (data?.data?.categories?.length > 0) {
          setWeddingFiltersList(data.data?.categories);
        } else {
          setWeddingFiltersList([]);
        }
      } else {
        setWeddingFiltersList([]);
      }
    } catch {
      setWeddingFiltersList([]);
    } finally {
      setWeddingFiltersLoading(false);
    }
  };

  /* GET wedding filters FUNCTION ENDS */

  /* GET honeymoon locations FUNCTION STARTS */

  // function which fetches the honeymoon locations
  const getHoneymoonLocations = async () => {
    setHoneymoonLocationsLoading(true);
    try {
      const { data } = await getHoneymoonLocationsCall();
      //console.log("HoneymoonLocationsResponse", data);
      if (data.success) {
        // success scenario
        //setHoneymoonLocationsLoading(false);
        if (data.data.length > 0) {
          setHoneymoonLocationsList(data.data);
        } else {
          setHoneymoonLocationsList([]);
        }
      } else {
        //setHoneymoonLocationsLoading(false);
        setHoneymoonLocationsList([]);
      }
    } catch {
      //setHoneymoonLocationsLoading(false);
      setHoneymoonLocationsList([]);
    } finally {
      setHoneymoonLocationsLoading(false);
    }
  };

  // function which fetches the engagement filters
  const getEngagementFilters = async () => {
    setEngagementFiltersLoading(true);
    try {
      const { data } = await getGlobalEventFiltersCall("engagement");
      //console.log("EngagementFiltersResponse", data);
      if (data.success) {
        // success scenario
        if (data?.data?.sub_events?.length > 0) {
          setEngagementFiltersList(data.data?.sub_events);
        } else {
          setEngagementFiltersList([]);
        }
      } else {
        setEngagementFiltersList([]);
      }
    } catch {
      setEngagementFiltersList([]);
    } finally {
      setEngagementFiltersLoading(false);
    }
  };

  /* GET Engagement filters FUNCTION ENDS */

  useEffect(() => {
    getWeddingFilters();
    getEngagementFilters();
    getHoneymoonLocations();
    getInspoCategories();
  }, []);

  useEffect(() => {
    if (
      loggedInUser?.profile_image !== null ||
      loggedInUser?.profile_image !== undefined ||
      loggedInUser?.profile_image !== ""
    ) {
      setProfileImage(loggedInUser?.profile_image);
    }
  }, [loggedInUser?.profile_image]);

  const handleDropdownToggle = () => {
    setShowPlanningDropdown(!showPlanningDropdown);
  };
  const handleEngagementDropdownToggle = () => {
    setShowEngagementDropdown(!showEngagementDropdown);
  };
  const handleWeddingDropdownToggle = () => {
    setShowWeddingDropdown(!showWeddingDropdown);
  };
  const handleHoneyMoonDropdownToggle = () => {
    setShowHoneyMoonDropdown(!showHoneyMoonDropdown);
  };
  const handleInspoDropdownToggle = () => {
    setShowInspoDropdown(!showInspoDropdown);
  };
  
  return (
    <>
      {/* Header for large screen */}
      <div className="user-header-lg">
      <div className="d-flex justify-content-between align-items-center custom-container py-4">
        <div
          className="d-flex justify-content-between align-items-center"
        >
          <Link to={
            loggedInUser !== null && signup_incomplete_user === null
            ? "/"
            : loggedInUser === null && signup_incomplete_user !== null
            ? "/"
            : "/"
          }>
            <img src={"/media/svgs/logo_1.svg"} alt="Wedding Match" width="200px" height="70px" />
          </Link>
          
          <div
            className="header-logo-separator"
            style={{ borderLeft: "2px solid lightgray", height: "100px" }}
          ></div>
          <a href={admin_settings?.bridal_site_link} target={"_blank"}>
            <img src={"/media/svgs/logo_2.svg"} alt="The Bridal Exchange" />
          </a>
          
        </div>
        <div>
          <ul className="d-flex list-unstyled header-menu-wrapper align-items-center">
            {loggedInUser?.role === "vendor" ||
            signup_incomplete_user?.role === "vendor" ? null : (
              <li className="m-2 nav_item_cus">
                <Link
                  to="/planning"
                  className="text-decoration-none text-reset"
                  onMouseOver={() => {
                    document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none";
                  }}
                  onClick={() =>
                    (document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  PLANNING
                </Link>
              </li>
            )}
            {loggedInUser?.role === "vendor" ||
            signup_incomplete_user?.role === "vendor" ? null : (
              <li className="m-2 nav_item_cus">
                <Link
                  to="/registry-main"
                  className="text-decoration-none text-reset"
                >
                  REGISTRY
                </Link>
              </li>
            )}
            {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
              <li className="m-2 nav_item_cus">
                <Link
                  to="/engagement"
                  className="text-decoration-none text-reset"
                  onMouseOver={() => {
                    document.getElementById(
                      "engagement-hover-dropdown"
                    ).style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "engagement-hover-dropdown"
                    ).style.display = "none";
                  }}
                  onClick={() =>
                    (document.getElementById(
                      "engagement-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  ENGAGEMENT
                </Link>
              </li>
            )}
            {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
              <li className="m-2 nav_item_cus">
                <Link
                  to="#"
                  className="text-decoration-none text-reset"
                  onMouseOver={() => {
                    document.getElementById(
                      "wedding-hover-dropdown"
                    ).style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "wedding-hover-dropdown"
                    ).style.display = "none";
                  }}
                  onClick={() =>
                    (document.getElementById(
                      "wedding-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  WEDDING
                </Link>
              </li>
            )}
            {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
              <li className="m-2 nav_item_cus">
                <Link
                  to="/honeymoon"
                  className="text-decoration-none text-reset"
                  onMouseOver={() => {
                    document.getElementById(
                      "honeymoon-hover-dropdown"
                    ).style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "honeymoon-hover-dropdown"
                    ).style.display = "none";
                  }}
                  onClick={() =>
                    (document.getElementById(
                      "honeymoon-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  HONEYMOON
                </Link>
              </li>
            )}
            {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
              <li className="m-2 nav_item_cus">
                <Link
                  to="#"
                  className="text-decoration-none text-reset"
                  onMouseOver={() => {
                    document.getElementById(
                      "inspo-hover-dropdown"
                    ).style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "inspo-hover-dropdown"
                    ).style.display = "none";
                  }}
                  onClick={() =>
                    (document.getElementById(
                      "inspo-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  INSPO
                </Link>
              </li>
            )}
            <li className="m-2 nav_item_cus">
                <Link
                  to="/events"
                  className="text-decoration-none text-reset"
                >
                  Events
                </Link>
              </li>
            <li
              className="m-2 nav_item_cus"
              style={{
                display:
                  loggedInUser !== null || signup_incomplete_user !== null
                    ? "none"
                    : "",
              }}
            >
              <Link
                to="/signupform"
                className="text-decoration-none text-reset"
              >
                SIGN UP
              </Link>
            </li>
            <li
              className="m-2 nav_item_cus"
              style={{
                display:
                  loggedInUser !== null || signup_incomplete_user !== null
                    ? "none"
                    : "",
              }}
            >
              <Link to="/signin" className="text-decoration-none text-reset">
                LOG IN
              </Link>
            </li>

            

            <li
              className="m-2"
              style={{
                display:
                  loggedInUser === null && signup_incomplete_user === null
                    ? "none"
                    : "",
              }}
            >
              <Dropdown show={showDropdown}>
              <Dropdown.Toggle variant="" id="dropdown-basic" onClick={() => setShowDropdown(!showDropdown)}>
                <img
                  src={
                    profileImage === null ||
                    profileImage === undefined ||
                    profileImage === ""
                      ? "/media/pictures/default-avatar.svg"
                      : profileImage
                  }
                  className="rounded-circle object-fit-cover"
                  width={"50px"}
                  height={"50px"}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                  <Link to={(signup_incomplete_user === null && loggedInUser !== null) ? "/dashboard" : (signup_incomplete_user !== null && signup_incomplete_user?.is_subscribed == "1" && signup_incomplete_user?.profile_setup == "1") ? "/dashboard" : (signup_incomplete_user !== null && signup_incomplete_user?.is_subscribed == "1" && signup_incomplete_user?.profile_setup == "0") ? "/vendor-account-create" : "/vendor-choose-plan"} className="w-100 p-3 d-flex align-items-center justify-content-center text-decoration-none text-reset rb-dropdown-item" onClick={() => {
                    setShowDropdown(false);
                  }}>
                    DASHBOARD
                  </Link>
                  <Link
                    to="/"
                    className="w-100 p-3 d-flex align-items-center justify-content-center text-decoration-none text-reset rb-dropdown-item"
                    onClick={() => {
                      setShowDropdown(false);
                      dispatch(setLoggedInUser(null));
                      dispatch(setSignupIncompleteUser(null));
                      localStorage.clear();
                    }}
                  >
                    LOG OUT
                  </Link>
              </Dropdown.Menu>
            </Dropdown>
            </li>
          </ul>
        </div>
        {/* <button 
                  className={`btn btn-primary text-dark text-hover-light font-weight-bold px-9 py-4 my-3`}
                  onClick={() => {
                    dispatch(setUserRole(null));
                    history.replace("/main-screen");
                  }}
                >
                  Back to Main Screen
                </button> */}
      </div>

      {/* WEDDING HOVER DROPDOWN BELOW */}
      <div
        id="wedding-hover-dropdown"
        className="p-7 border-top border-bottom position-absolute bg-white"
        style={{
          display: "none",
          marginTop: "-54px",
          zIndex: "9999",
          width: "100%",
        }}
        onMouseOver={() => {
          document.getElementById("wedding-hover-dropdown").style.display =
            "flex";
        }}
        onMouseOut={() => {
          document.getElementById("wedding-hover-dropdown").style.display =
            "none";
        }}
      >
        <div className="p-5 w-100">
          <h3 className="hover-menu-title text-uppercase">VENDORS</h3>
          <div className="d-flex row pt-5 w-100">
            {weddingFiltersLoading ? (
              <div className="d-flex align-items-center justify-content-center mx-auto">
                <MenuSkeletonLoader />
              </div>
            ) : !weddingFiltersLoading && weddingFiltersList?.length === 0 ? (
              <div className="d-flex align-items-center justify-content-center mx-auto">
                <DataNotFoundEmpty description={"No data!"} />
              </div>
            ) : (
              weddingFiltersList?.map((el, idx) => {
                return (
                  <Link
                    key={el?.id}
                    to={`/wedding/${el?.slug}`}
                    className="d-flex align-items-center col-lg-3 col-md-4 col-sm-12 hover-menu-items-list p-5"
                    onClick={() =>
                      (document.getElementById(
                        "wedding-hover-dropdown"
                      ).style.display = "none")
                    }
                  >
                    {el?.name === "Bands and DJs" ? (
                      <img src="/media/svgs/band-DJ.svg" />
                    ) : el?.name === "Catering" ? (
                      <img src="/media/svgs/catering.svg" />
                    ) : el?.name === "Favors and Gifts" ? (
                      <img src="/media/svgs/gifts.svg" />
                    ) : el?.name === "Photography" ? (
                      <img src="/media/svgs/photography.svg" />
                    ) : el?.name === "Transportation" ? (
                      <img src="/media/svgs/transportation.svg" />
                    ) : el?.name === "Videography" ? (
                      <img src="/media/svgs/videography.svg" />
                    ) : el?.name === "Bartenders" ? (
                      <img src="/media/svgs/bartenders.svg" />
                    ) : el?.name === "Ceremony Music" ? (
                      <img src="/media/svgs/ceremony-music.svg" />
                    ) : el?.name === "Flowers" ? (
                      <img src="/media/svgs/flowers.svg" />
                    ) : el?.name === "Photobooths" ? (
                      <img src="/media/svgs/photobooths.svg" />
                    ) : el?.name === "Travel Agents" ? (
                      <img src="/media/svgs/travel-agents.svg" />
                    ) : el?.name === "Wedding Planners" ? (
                      <img src="/media/svgs/wedding-planners.svg" />
                    ) : el?.name === "Beauty and Wellness" ? (
                      <img src="/media/svgs/beauty-wellness.svg" />
                    ) : el?.name === "Dress and Attire" ? (
                      <img src="/media/svgs/dress-attire.svg" />
                    ) : el?.name === "Jewelry" ? (
                      <img src="/media/svgs/jewelry.svg" />
                    ) : el?.name === "Rehearsal Dinner" ? (
                      <img src="/media/svgs/dinner.svg" />
                    ) : el?.name === "Unique Vendors" ? (
                      <img src="/media/svgs/unique-vendors.svg" />
                    ) : el?.name === "Cakes and Desserts" ? (
                      <img src="/media/svgs/cakes.svg" />
                    ) : el?.name === "Event Rentals" ? (
                      <img src="/media/svgs/event-rentals.svg" />
                    ) : el?.name === "Officiants" ? (
                      <img src="/media/svgs/officiants.svg" />
                    ) : el?.name === "Stationery and Invites" ? (
                      <img src="/media/svgs/stationery.svg" />
                    ) : el?.name === "Venues" ? (
                      <img src="/media/svgs/venues.svg" />
                    ) : (
                      <></>
                    )}
                    <span className="ml-3">{el?.name}</span>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </div>
      {/* PLANNING HOVER DROPDOWN BELOW */}
      <div
        id="planning-hover-dropdown"
        className="w-100 p-5 position-absolute align-items-center bg-white justify-content-center border"
        style={{
          minHeight: "300px",
          display: "none",
          zIndex: "9999",
          marginTop: "-54px",
        }}
        onMouseOver={() => {
          document.getElementById("planning-hover-dropdown").style.display =
            "flex";
        }}
        onMouseOut={() => {
          document.getElementById("planning-hover-dropdown").style.display =
            "none";
        }}
      >
        <div className="d-flex row">
          <div className="p-5 col-lg-6">
            <div>
              <h6 className="hover-menu-title">ORGANIZATION</h6>
            </div>
            <div className="row">
              <div className="d-flex col-lg-6 px-3 py-2 align-items-center">
                <img
                  src="/media/svgs/planning-guest-list.svg"
                  width="30px"
             
                />
                <Link
                  to={loggedInUser === null ? "#" : "/checklist"}
                  className="hover-menu-items-list"
                >
                  <span
                    className="ml-3"
                    onClick={() => {
                      document.getElementById(
                        "planning-hover-dropdown"
                      ).style.display = "none";
                      if (loggedInUser === null) {
                        toast("info", "Please login first to see this page", 5);
                      }
                    }}
                  >
                    Checklist
                  </span>
                </Link>
              </div>
              <div className="d-flex col-lg-6 px-3 py-2 align-items-center">
                <img
                  src="/media/svgs/seating-chart.svg"
                  width="30px"
                 
                />
                <Link
                  to={loggedInUser === null ? "#" : "/seating-chart"}
                  className="hover-menu-items-list"
                  onClick={() => {
                    document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none";
                    dispatch(setHowToDecideClicked(false));
                    if (loggedInUser === null) {
                      toast("info", "Please login first to see this page", 5);
                    }
                  }}
                >
                  <span className="ml-3">Seating Chart</span>
                </Link>
              </div>
              <div className="d-flex col-lg-6 px-3 py-2 align-items-center">
                <img
                  src="/media/svgs/budget-tool.svg"
                  width="30px"
                
                />
                <Link
                  to="/budget-tool"
                  className="hover-menu-items-list"
                  onClick={() =>
                    (document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  <span className="ml-3">Budget Tool</span>
                </Link>
              </div>
              <div className="d-flex col-lg-6 px-3 py-2 align-items-center">
                <img
                  src="/media/svgs/hashtag-generator.svg"
                  width="30px"
             
                />
                <Link
                  to={loggedInUser === null ? "#" : "/hashtag-generator"}
                  className="hover-menu-items-list"
                  onClick={() => {
                    document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none";
                    if (loggedInUser === null) {
                      toast("info", "Please login first to see this page", 5);
                    }
                  }}
                >
                  <span className="ml-3">Hashtag Generator</span>
                </Link>
              </div>
              <div className="d-flex col-lg-6 px-3 py-2 align-items-center">
                <img
                  src="/media/svgs/planning-guest-list.svg"
                  width="30px"
                 
                />
                <Link
                  to={loggedInUser === null ? "/login-guestlist" : "/guestlist"}
                  className="hover-menu-items-list"
                  onClick={() => {
                    document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none";
                    if (loggedInUser === null) {
                      toast("info", "Please login first to see this page", 5);
                    }
                  }}
                >
                  <span className="ml-3">Guest List</span>
                </Link>
              </div>
              <div className="d-flex col-lg-6 px-3 py-2 align-items-center">
                <img
                  src="/media/svgs/color-inspiration.svg"
                  width="30px"
              
                />
                <Link
                  to="/color-inspiration"
                  className="hover-menu-items-list"
                  onClick={() =>
                    (document.getElementById(
                      "planning-hover-dropdown"
                    ).style.display = "none")
                  }
                >
                  <span className="ml-3">Color Inspiration</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="p-5 col-lg-6">
            <div className="mb-6">
              <h6 className="hover-menu-title">
                <img src="/media/svgs/stationery.svg" />
                <span className="ml-3">STATIONERY + INVITES</span>
              </h6>
            </div>



            <div className="row "
             
            >
              <div className="col-md-12 col-lg-12 z-3">
  
             
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/iris.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%",
                }}
              >
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/turning.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              >
            </div>
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/sapphire.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              ></div>
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/sugar.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              ></div>
              </div>
            </div>

            <div className="mb-6"
            style={{
              backgroundColor: "#EFD7CE",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px", // Set a fixed height for the container
              marginTop:'10px',
            }}
            > 
           < h6 className="fw-800">COMING SOON!</h6>
            </div>
          </div>       
        </div>
      </div>

      {/* ENGAGEMENT HOVER DROPDOWN BELOW */}
      <div
        id="engagement-hover-dropdown"
        className="w-100 p-5 position-absolute align-items-center justify-content-center border bg-white"
        style={{
          minHeight: "300px",
          display: "none",
          zIndex: "9999",
          marginTop: "-54px",
        }}
        onMouseOver={() => {
          document.getElementById("engagement-hover-dropdown").style.display =
            "flex";
        }}
        onMouseOut={() => {
          document.getElementById("engagement-hover-dropdown").style.display =
            "none";
        }}
      >
        <div className="d-flex row full-width">
          <div className="p-5 col-lg-6">
            <div>
              <h6 className="hover-menu-title">VENDORS</h6>
            </div>
            <div className="row ">
              {engagementFiltersLoading ? (
                <div className="d-flex align-items-center justify-content-center mx-auto">
                  <EngagementMenuSkeletonLoader />
                </div>
              ) : !engagementFiltersLoading &&
                engagementFiltersList?.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center mx-auto">
                  <DataNotFoundEmpty description={"No data!"} />
                </div>
              ) : (
                engagementFiltersList?.map((el, idx) => {
                  return (
                    <div className="d-flex col-lg-12 px-3 py-2 align-items-center">
                      {
                        el?.slug === "bachelorette-party" ?
                        <img
                          src="/media/icons/bacheloretteparty.png"
                          style={{ objectFit: 'contain' }}
                          width="30px"
   
                        />
                        :
                        el?.slug === "bachelor-party" ?
                        <img
                          src="/media/icons/bachelorparty.png"
                          width="30px"
                          style={{ objectFit: 'contain' }}
     
                        />
                        :
                        el?.slug === "proposal" ?
                        <img
                          src="/media/icons/engagementparty.png"
                          width="30px"
                          style={{ objectFit: 'contain' }}
                         
                        />
                        :
                        el?.slug === "engagement-party" ?
                        <img
                          src="/media/icons/engagementparty.png"
                          width="30px"
                          style={{ objectFit: 'contain' }}
                         
                        />
                        :
                        el?.slug === "bridal-shower" ?
                        <img
                          src="/media/icons/bridalshower.png"
                          width="30px"
                          style={{ objectFit: 'contain' }}
                
                        />
                        :
                        <></>
                      }
                      <Link
                        to={`/engagement/${el?.slug}`}
                        className="hover-menu-items-list"
                      >
                        <span className="ml-3">{el?.name}</span>
                      </Link>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="p-5 col-lg-6">
            <div className="mb-6">
              <h6 className="hover-menu-title">
                <img src="/media/svgs/stationery.svg" />
                <span className="ml-3">STATIONERY + INVITES</span>
              </h6>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/iris.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              >
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/turning.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              >
            </div>
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/sapphire.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              ></div>
              </div>
              <div className="col-md-6 col-lg-3">
              <div
                className="bg-secondary"
                style={{
                  backgroundImage: `url('/media/engagement/sugar.png')`, // Use url() to specify the image path
                  backgroundSize : 'cover',
                  height: "150px",
                  minWidth: "100%"
                }}
              ></div>
              </div>
            </div>
            <div className="mb-6"
            style={{
              backgroundColor: "#EFD7CE",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px", // Set a fixed height for the container
              marginTop:'10px',
            }}
            > 
           < h6 className="fw-800">COMING SOON!</h6>
            </div>
          </div>
        </div>
      </div>
      {/* HONEYMOON HOVER DROPDOWN BELOW */}
      <div
        id="honeymoon-hover-dropdown"
        className="w-100 p-7 border-top border-bottom position-absolute bg-white"
        style={{ display: "none", marginTop: "-54px", zIndex: "9999" }}
        onMouseOver={() => {
          document.getElementById("honeymoon-hover-dropdown").style.display =
            "flex";
        }}
        onMouseOut={() => {
          document.getElementById("honeymoon-hover-dropdown").style.display =
            "none";
        }}
      >
        <div className="p-5">
          <h3 className="text-uppercase">DESTINATIONS</h3>
          <div className="d-flex row pt-5">
            {honeymoonLocationsLoading ? (
              <div className="d-flex justify-content-center align-items-center p-5">
                <MenuSkeletonLoader />
              </div>
            ) : !honeymoonLocationsLoading &&
              honeymoonLocationsList?.length === 0 ? (
              <div className=""><DataNotFoundEmpty description={"No Destinations!"} /></div>
            ) : (
              honeymoonLocationsList?.map((el) => {
                return (
                  <Link
                    key={el?.id}
                    to="/honeymoon/adventure"
                    className="d-flex align-items-center col-lg-3 col-md-4 col-sm-12 hover-menu-items-list px-5 py-2"
                    onClick={() => {
                      document.getElementById(
                        "honeymoon-hover-dropdown"
                      ).style.display = "none";
                      dispatch(
                        setHoneymoonLocationSelected({
                          honeymoonLocationID: el?.id,
                          honeymonnLocationName: el?.name,
                        })
                      );
                    }}
                  >
                    <span className="ml-2">{el?.name}</span>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* INSPO HOVER DROPDOWN BELOW */}
      <div
        id="inspo-hover-dropdown"
        className="w-100 p-7 border-top border-bottom position-absolute bg-white"
        style={{ display: "none", marginTop: "-54px", zIndex: "9999" }}
        onMouseOver={() => {
          document.getElementById("inspo-hover-dropdown").style.display =
            "flex";
        }}
        onMouseOut={() => {
          document.getElementById("inspo-hover-dropdown").style.display =
            "none";
        }}
      >
        <div className="p-5">
          <h3 className="text-uppercase">INSPIRATION</h3>
          <div className="d-flex row pt-5">
            {inspoCategoriesLoading ? (
              <div className="d-flex justify-content-center align-items-center p-5">
                <MenuSkeletonLoader />
              </div>
            ) : !inspoCategoriesLoading &&
              inspoCategoriesList?.length === 0 ? (
              <div className="p-5"><DataNotFoundEmpty description={"No Inspo Categories!"} /></div>
            ) : (
              inspoCategoriesList?.map((el) => {
                return (
                  <Link
                    key={el?.id}
                    to={`/inspo-blog/category/${el?.slug}`}
                    className="d-flex align-items-center col-lg-6 col-md-6 col-sm-12 hover-menu-items-list px-5 py-2"
                    onClick={() => {
                      document.getElementById(
                        "inspo-hover-dropdown"
                      ).style.display = "none";
                    }}
                  >
                    <span className="ml-2">{el?.name}</span>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </div>
      </div>

      {/* Header for medium and small screens */}
      <div className="user-header-sm">
        <div className='d-flex justify-content-between align-items-center container py-3'>
                {/* show below logo container when screen is large or medium */}
                <div className='big-logo-container'>
                    <Link to={
                        (loggedInUser !== null && signup_incomplete_user === null) ? "/" : (loggedInUser === null && signup_incomplete_user !== null) ? "/" : "/"
                        } className='d-flex align-items-center'>
                       <img src={"/media/svgs/logo_1.svg"} alt="Wedding Match" width="200px" height="70px" />


                    </Link>
                    <div className='mx-5' style={{ borderLeft: '2px solid lightgray', height: '100px' }}>
                    </div>
                    <a className='d-flex align-items-center' href={admin_settings?.bridal_site_link} target={"_blank"}>
                        <img src={"/media/svgs/logo_2.svg"} />
                    </a>
                </div>
                {/* show below logo container when screen is small */}
                <Link to={
                        (loggedInUser !== null && signup_incomplete_user === null) ? "/" : (loggedInUser === null && signup_incomplete_user !== null) ? "/" : "/"
                        } className='small-logo-container'>
                  <img src={"/media/svgs/logo_1.svg"} alt="Wedding Match" width="200px" height="70px" />

                </Link>

                <FaBars role="button" fontSize={"40px"} className="text-primary" onClick={() => { setShowTopMenu(true); }} />
            </div>
      </div>

        {/* Top Menu for small and medium screens */}
        {
          showTopMenu && 
          <div
            className={`topMenuForSmScreensContainer`}
          >
           <div className="w-100 d-flex justify-content-between p-2 bg-secondary align-items-center topMenuForSmScreensHeader">
            <ul className="d-flex list-unstyled align-items-center my-auto">
            <li
              className="m-2"
              style={{
                display:
                  loggedInUser !== null || signup_incomplete_user !== null
                    ? "none"
                    : "",
              }}
            >
              <Link
                to="/signupform"
                className="text-decoration-none text-reset"
                onClick={() => {
                  setShowTopMenu(false);
                }}
              >
                <b>SIGN UP</b>
              </Link>
            </li>
            <li
              className="m-2"
              style={{
                display:
                  loggedInUser !== null || signup_incomplete_user !== null
                    ? "none"
                    : "",
              }}
            >
              <Link to="/signin" className="text-decoration-none text-reset" onClick={() => {
                setShowTopMenu(false);
              }}>
                <b>LOG IN</b>
              </Link>
            </li>

            

            <li
              className="m-2"
              style={{
                display:
                  loggedInUser === null && signup_incomplete_user === null
                    ? "none"
                    : "",
              }}
            >
              <Dropdown show={showDropdown}>
              <Dropdown.Toggle variant="" id="dropdown-basic" onClick={() => setShowDropdown(!showDropdown)}>
                <img
                  src={
                    profileImage === null ||
                    profileImage === undefined ||
                    profileImage === ""
                      ? "/media/pictures/default-avatar.svg"
                      : profileImage
                  }
                  className="rounded-circle object-fit-cover"
                  width={"50px"}
                  height={"50px"}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                  <Link to={(signup_incomplete_user === null && loggedInUser !== null) ? "/dashboard" : (signup_incomplete_user !== null && signup_incomplete_user?.is_subscribed == "1" && signup_incomplete_user?.profile_setup == "1") ? "/dashboard" : (signup_incomplete_user !== null && signup_incomplete_user?.is_subscribed == "1" && signup_incomplete_user?.profile_setup == "0") ? "/vendor-account-create" : "/vendor-choose-plan"} className="w-100 p-3 d-flex align-items-center justify-content-center text-decoration-none text-reset rb-dropdown-item" onClick={() => {
                    setShowDropdown(false);
                    setShowTopMenu(false);
                  }}>
                    DASHBOARD
                  </Link>
                  <Link
                    to="/"
                    className="w-100 p-3 d-flex align-items-center justify-content-center text-decoration-none text-reset rb-dropdown-item"
                    onClick={() => {
                      setShowDropdown(false);
                      setShowTopMenu(false);
                      dispatch(setLoggedInUser(null));
                      dispatch(setSignupIncompleteUser(null));
                      localStorage.clear();
                    }}
                  >
                    LOG OUT
                  </Link>
              </Dropdown.Menu>
            </Dropdown>
            </li>
            </ul>
            <FaRegTimesCircle role="button" fontSize={"40px"} className="text-primary" onClick={() => {
              setShowTopMenu(false);
            }} />
           </div>

           <div className="mt-30 p-2">
           {loggedInUser?.role === "vendor" || signup_incomplete_user?.role === "vendor" ? null : (
                <>
                  <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
                    <div>
                      <Link
                        to="/planning"
                        className="text-decoration-none text-reset"
                        onClick={() => {
                          setShowPlanningDropdown(false);
                          setShowTopMenu(false);
                        }}
                      >
                        <b>PLANNING</b>
                      </Link>
                    </div>
                    <div>
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={showPlanningDropdown ? 'true' : 'false'}
                        onClick={handleDropdownToggle}
                      >
                      </button>
                    </div>
                  </div>

                  {showPlanningDropdown && (
                    <div className="align-items-center bg-white justify-content-center mt-2 px-7">
                    <div className="d-flex row">
                      <div className="p-1 col-12">
                        {/* <div>
                          <h6 className="hover-menu-title">ORGANIZATION</h6>
                        </div> */}
                        <div className="row">
                          <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                            <img
                              src="/media/svgs/planning-guest-list.svg"
                              width="30px"
                             
                            />
                            <Link
                              to={loggedInUser === null ? "#" : "/checklist"}
                              className="hover-menu-items-list"
                            >
                              <span
                                className="ml-3"
                                onClick={() => {
                                  if (loggedInUser === null) {
                                    toast("info", "Please login first to see this page", 5);
                                  }else{
                                    setShowPlanningDropdown(false);
                                    setShowTopMenu(false);
                                  }
                                }}
                              >
                                Checklist
                              </span>
                            </Link>
                          </div>
                          <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                            <img
                              src="/media/svgs/seating-chart.svg"
                              width="30px"
                           
                            />
                            <Link
                              to={loggedInUser === null ? "#" : "/seating-chart"}
                              className="hover-menu-items-list"
                              onClick={() => {
    
                                dispatch(setHowToDecideClicked(false));
                                if (loggedInUser === null) {
                                  toast("info", "Please login first to see this page", 5);
                                }else{
                                  setShowPlanningDropdown(false);
                                  setShowTopMenu(false);
                                }
                              }}
                            >
                              <span className="ml-3">Seating Chart</span>
                            </Link>
                          </div>
                          <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                            <img
                              src="/media/svgs/budget-tool.svg"
                              width="30px"
                         
                            />
                            <Link
                              to="/budget-tool"
                              className="hover-menu-items-list"
                              onClick={() => {
                                  setShowPlanningDropdown(false);
                                  setShowTopMenu(false);
                                }}
                            >
                              <span className="ml-3">Budget Tool</span>
                            </Link>
                          </div>
                          <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                            <img
                              src="/media/svgs/hashtag-generator.svg"
                              width="30px"
                           
                            />
                            <Link
                              to={loggedInUser === null ? "#" : "/hashtag-generator"}
                              className="hover-menu-items-list"
                              onClick={() => {
                                if (loggedInUser === null) {
                                  toast("info", "Please login first to see this page", 5);
                                }else{
                                  setShowPlanningDropdown(false);
                                  setShowTopMenu(false);
                                }
                              }}
                            >
                              <span className="ml-3">Hashtag Generator</span>
                            </Link>
                          </div>
                          <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                            <img
                              src="/media/svgs/planning-guest-list.svg"
                              width="30px"
                          
                            />
                            <Link
                              to={loggedInUser === null ? "/login-guestlist" : "/guestlist"}
                              className="hover-menu-items-list"
                              onClick={() => {
                                if (loggedInUser === null) {
                                  toast("info", "Please login first to see this page", 5);
                                }else{
                                  setShowPlanningDropdown(false);
                                  setShowTopMenu(false);
                                }
                              }}
                            >
                              <span className="ml-3">Guest List</span>
                            </Link>
                          </div>
                          <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                            <img
                              src="/media/svgs/color-inspiration.svg"
                              width="30px"
                          
                            />
                            <Link
                              to="/color-inspiration"
                              className="hover-menu-items-list"
                              onClick={() => {
                                setShowPlanningDropdown(false);
                                setShowTopMenu(false);
                              }}
                            >
                              <span className="ml-3">Color Inspiration</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                            
                      {/* <div className="p-5 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="mb-6">
                          <h6 className="hover-menu-title">
                            <img src="/media/svgs/stationery.svg" className="mr-3" />
                            STATIONERY + INVITES
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-lg-3">
                            <div
                              className="bg-secondary"
                              style={{ height: "150px", minWidth: "100%" }}
                            ></div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div
                              className="bg-secondary"
                              style={{ height: "150px", minWidth: "100%" }}
                            ></div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div
                              className="bg-secondary"
                              style={{ height: "150px", minWidth: "100%" }}
                            ></div>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div
                              className="bg-secondary"
                              style={{ height: "150px", minWidth: "100%" }}
                            ></div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  )}
                </>
              )}

            {loggedInUser?.role === "vendor" ||
            signup_incomplete_user?.role === "vendor" ? null : (
              <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
              <div>
              <Link
                  to="/registry-main"
                  className="text-decoration-none text-reset"
                  onClick={() => {
                    setShowTopMenu(false);
                  }}
                >
                  <b>REGISTRY</b>
                </Link>
              </div>
              <div>
                <button
                  className="btn  dropdown-toggle "
                  style={{ color : '#fff'}}
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  disabled
                >
                </button>
              </div>
            </div>
            )}
            {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
                <>
              <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
                    <div>
                      <Link
                      to="/engagement"
                      className="text-decoration-none text-reset"
                      onClick={() => {
                          setShowEngagementDropdown(false);
                          setShowTopMenu(false);
                      }}
                    >
                  <b>ENGAGEMENT</b>
                </Link>
                    </div>
                    <div>
                      <button
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={showEngagementDropdown ? 'true' : 'false'}
                        onClick={handleEngagementDropdownToggle}
                      >
                      </button>
                    </div>
                  </div>
                  {showEngagementDropdown && (
                    <div
                className="align-items-center justify-content-center bg-white mt-2 px-7"
              >
                <div className="d-flex row">
                  <div className="p-1 col-12">
                    {/* <div>
                      <h6 className="hover-menu-title">VENDORS</h6>
                    </div> */}
                    <div className="row">
                      {engagementFiltersLoading ? (
                        <div className="d-flex align-items-center justify-content-center mx-auto">
                          <EngagementMenuSkeletonLoader />
                        </div>
                      ) : !engagementFiltersLoading &&
                        engagementFiltersList?.length === 0 ? (
                        <div className="d-flex align-items-center justify-content-center mx-auto">
                          <DataNotFoundEmpty description={"No data!"} />
                        </div>
                      ) : (
                        engagementFiltersList?.map((el, idx) => {
                          return (
                            <div className="d-flex col-xl-3 col-lg-4 col-md-6 col-sm-12 px-3 py-2 align-items-center">
                              {
                                el?.slug === "bachelorette-party" ?
                                <img
                                  src="/media/icons/bacheloretteparty.png"
                                  width="30px"
                                  
                                 
                                />
                                :
                                el?.slug === "bachelor-party" ?
                                <img
                                  src="/media/icons/bachelorparty.png"
                                  width="30px"
                                
                                />
                                :
                                el?.slug === "proposal" ?
                                <img
                                  src="/media/icons/engagementparty.png"
                                  width="30px"
                               
                                />
                                :
                                el?.slug === "engagement-party" ?
                                <img
                                  src="/media/icons/engagementparty.png"
                                  width="30px"
                                
                                />
                                :
                                el?.slug === "bridal-shower" ?
                                <img
                                  src="/media/icons/bridalshower.png"
                                  width="30px"
                                 
                                />
                                :
                                <></>
                              }
                              <Link
                                to={`/engagement/${el?.slug}`}
                                className="hover-menu-items-list"
                                onClick={() => {
                                  setShowEngagementDropdown(false);
                                  setShowTopMenu(false);
                                }}
                              >
                                <span className="ml-3">{el?.name}</span>
                              </Link>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                      
                  {/* <div className="p-5 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="mb-6">
                      <h6 className="hover-menu-title">
                        <img src="/media/svgs/stationery.svg" />
                        <span className="ml-3">STATIONERY + INVITES</span>
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-3">
                        <div
                          className="bg-secondary"
                          style={{ height: "150px", minWidth: "100%" }}
                        ></div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div
                          className="bg-secondary"
                          style={{ height: "150px", minWidth: "100%" }}
                        ></div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div
                          className="bg-secondary"
                          style={{ height: "150px", minWidth: "100%" }}
                        ></div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div
                          className="bg-secondary"
                          style={{ height: "150px", minWidth: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
                  )}
</>
)}

              {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
                <>
                  <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
                    <div>
                      <Link
                      to="/wedding"
                      className="text-decoration-none text-reset"
                      onClick={() => {
                          setShowWeddingDropdown(false);
                          setShowTopMenu(false);
                      }}
                    >
                  <b>WEDDING</b>
                </Link>
                    </div>
                    <div>
                      <button
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={showWeddingDropdown ? 'true' : 'false'}
                        onClick={handleWeddingDropdownToggle}
                      >
                      </button>
                    </div>
                  </div>
                  {showWeddingDropdown && (
                  <div
                  className="bg-white mt-2 px-5"
                >
                  <div className="w-100">
                    {/* <h3 className="hover-menu-title text-uppercase">VENDORS</h3> */}
                    <div className="d-flex row w-100">
                      {weddingFiltersLoading ? (
                        <div className="d-flex flex-wrap align-items-center justify-content-center mx-auto">
                          <MenuSkeletonLoader />
                        </div>
                      ) : !weddingFiltersLoading && weddingFiltersList?.length === 0 ? (
                        <div className="d-flex align-items-center justify-content-center mx-auto">
                          <DataNotFoundEmpty description={"No data!"} />
                        </div>
                      ) : (
                        weddingFiltersList?.map((el, idx) => {
                          return (
                            <Link
                              key={el?.id}
                              to={`/wedding/${el?.slug}`}
                              className="d-flex align-items-center col-lg-3 col-md-4 col-sm-12 hover-menu-items-list p-5"
                              onClick={() => {
                                setShowWeddingDropdown(false);
                                setShowTopMenu(false);
                              }}
                            >
                              {el?.name === "Bands and DJs" ? (
                                <img src="/media/svgs/band-DJ.svg" />
                              ) : el?.name === "Catering" ? (
                                <img src="/media/svgs/catering.svg" />
                              ) : el?.name === "Favors and Gifts" ? (
                                <img src="/media/svgs/gifts.svg" />
                              ) : el?.name === "Photography" ? (
                                <img src="/media/svgs/photography.svg" />
                              ) : el?.name === "Transportation" ? (
                                <img src="/media/svgs/transportation.svg" />
                              ) : el?.name === "Videography" ? (
                                <img src="/media/svgs/videography.svg" />
                              ) : el?.name === "Bartenders" ? (
                                <img src="/media/svgs/bartenders.svg" />
                              ) : el?.name === "Ceremony Music" ? (
                                <img src="/media/svgs/ceremony-music.svg" />
                              ) : el?.name === "Flowers" ? (
                                <img src="/media/svgs/flowers.svg" />
                              ) : el?.name === "Photobooths" ? (
                                <img src="/media/svgs/photobooths.svg" />
                              ) : el?.name === "Travel Agents" ? (
                                <img src="/media/svgs/travel-agents.svg" />
                              ) : el?.name === "Wedding Planners" ? (
                                <img src="/media/svgs/wedding-planners.svg" />
                              ) : el?.name === "Beauty and Wellness" ? (
                                <img src="/media/svgs/beauty-wellness.svg" />
                              ) : el?.name === "Dress and Attire" ? (
                                <img src="/media/svgs/dress-attire.svg" />
                              ) : el?.name === "Jewelry" ? (
                                <img src="/media/svgs/jewelry.svg" />
                              ) : el?.name === "Rehearsal Dinner" ? (
                                <img src="/media/svgs/dinner.svg" />
                              ) : el?.name === "Unique Vendors" ? (
                                <img src="/media/svgs/unique-vendors.svg" />
                              ) : el?.name === "Cakes and Desserts" ? (
                                <img src="/media/svgs/cakes.svg" />
                              ) : el?.name === "Event Rentals" ? (
                                <img src="/media/svgs/event-rentals.svg" />
                              ) : el?.name === "Officiants" ? (
                                <img src="/media/svgs/officiants.svg" />
                              ) : el?.name === "Stationery and Invites" ? (
                                <img src="/media/svgs/stationery.svg" />
                              ) : el?.name === "Venues" ? (
                                <img src="/media/svgs/venues.svg" />
                              ) : (
                                <></>
                              )}
                              <span className="ml-3">{el?.name}</span>
                            </Link>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
                  
                  
                  
                  
                  )}
               </>
             )}

              {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
                <>
                <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
                    <div>
                      <Link
                      to="/honeymoon"
                      className="text-decoration-none text-reset"
                      onClick={() => {
                          setShowHoneyMoonDropdown(false);
                          setShowTopMenu(false);
                      }}
                    >
                  <b>HONEYMOON</b>
                </Link>
                    </div>
                    <div>
                      <button
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={showHoneyMoonDropdown ? 'true' : 'false'}
                        onClick={handleHoneyMoonDropdownToggle}
                      >
                      </button>
                    </div>
                  </div>
                  {showHoneyMoonDropdown && (
                    <div
                  className="bg-white mt-2 px-5"
                >
                  <div className="">
                    {/* <h3 className="text-uppercase">DESTINATIONS</h3> */}
                    <div className="row">
                      {honeymoonLocationsLoading ? (
                        <div className="d-flex justify-content-center align-items-center mx-auto">
                          <MenuSkeletonLoader />
                        </div>
                      ) : !honeymoonLocationsLoading &&
                        honeymoonLocationsList?.length === 0 ? (
                        <div className=""><DataNotFoundEmpty description={"No Destinations!"} /></div>
                      ) : (
                        honeymoonLocationsList?.map((el) => {
                          return (
                            <Link
                              key={el?.id}
                              to="/honeymoon/adventure"
                              className="d-flex align-items-center col-lg-3 col-md-4 col-sm-12 hover-menu-items-list px-5 py-2"
                              onClick={() => {
                                setShowTopMenu(false);
                                setShowHoneyMoonDropdown(false);
                                dispatch(
                                  setHoneymoonLocationSelected({
                                    honeymoonLocationID: el?.id,
                                    honeymonnLocationName: el?.name,
                                  })
                                );
                              }}
                            >
                              <span className="ml-2">{el?.name}</span>
                            </Link>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
                  )}
                  </>
              )}
              {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
                <>
                <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
                  <div>
                    <Link
                      to="#"
                      className="text-decoration-none text-reset"
                      onClick={() => {
                        setShowInspoDropdown(false);
                        setShowTopMenu(false);
                      }}
                    >
                      <b>INSPO</b>
                    </Link>
                  </div>
                  <div>
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={showInspoDropdown ? 'true' : 'false'}
                      onClick={handleInspoDropdownToggle}
                    ></button>
                  </div>
                </div>

                  {showInspoDropdown && (
                <div className="bg-white mt-2 px-5" >
                  <div className="">
                    {/* <h3 className="text-uppercase">INSPIRATION</h3> */}
                    <div className="row">
                      {inspoCategoriesLoading ? (
                        <div className="d-flex justify-content-center align-items-center mx-auto">
                          <MenuSkeletonLoader />
                        </div>
                      ) : !inspoCategoriesLoading &&
                        inspoCategoriesList?.length === 0 ? (
                        <div className="p-5"><DataNotFoundEmpty description={"No Inspo Categories!"} /></div>
                      ) : (
                        inspoCategoriesList?.map((el) => {
                          return (
                            <Link
                              key={el?.id}
                              to={`/inspo-blog/category/${el?.slug}`}
                              className="d-flex align-items-center col-lg-3 col-md-4 col-sm-12 hover-menu-items-list px-5 py-2"
                              onClick={() => {
                                setShowInspoDropdown(false);
                                setShowTopMenu(false);
                              }}
                            >
                              <span className="ml-2">{el?.name}</span>
                            </Link>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
                  )}
                </>
              )}
            {(loggedInUser == null ||
              loggedInUser?.role === "vendor" ||
              loggedInUser?.role === "customer") && (
              <div className="d-flex justify-content-between border-bottom p-3">
              <div>
              <Link
                    to="/events"
                    className="text-decoration-none text-reset"
                    onClick={() => {
                        setShowTopMenu(false);
                    }}
                  >
                    <b>EVENTS</b>
                  </Link>
              </div>
              <div>
                <button
                  className="btn  dropdown-toggle "
                  style={{ color : '#fff'}}
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  disabled
                >
                </button>
              </div>
            </div>
            )}
           </div>
        </div>
      }
    </>
  );
}

export default Header;