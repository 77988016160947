import {
    SET_HONEYMOON_LOCATION_SELECTED
  } from "./actionTypes";

 
  export const setHoneymoonLocationSelected = val => {
    return {
      type: SET_HONEYMOON_LOCATION_SELECTED,
      payload: val,
    };
  };