import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_HAS_ACCESS_TO_SITE
  } from "./actionTypes";

  const initialState = {
    has_access_to_site : {
      tokenExpiryTime: null,
      pages: []
    }
  };

  const hasAccessToSite = persistReducer(
  { storage, key: "v726-demo1-has_access_to_site", whitelist: ["has_access_to_site"] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_HAS_ACCESS_TO_SITE:
        state = { ...state, has_access_to_site: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });

  export default hasAccessToSite;
  