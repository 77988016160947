import {
    SET_ADMIN_SETTINGS
  } from "./actionTypes";

 
  export const setAdminSettings = val => {
    return {
      type: SET_ADMIN_SETTINGS,
      payload: val,
    };
  };