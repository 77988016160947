import {
    SET_IS_SITE_PASSWORD_PROTECTED
  } from "./actionTypes";

 
  export const setIsSitePasswordProtected = val => {
    return {
      type: SET_IS_SITE_PASSWORD_PROTECTED,
      payload: val,
    };
  };