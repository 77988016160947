import React from "react";
import { setUserRole } from "../store/userRole/actions";
import { useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../_metronic/_helpers";

function MainScreen(){

    const dispatch = useDispatch();

    return(
        <>
        <div className="vh-100">
            <div className='banner_img'>
                <div className="p-5">
                    <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/logo.png")}
                    />
                </div>
                <div className="p-5 text-center mt-5">
                    <h3 className="font-size-h1 mb-5 text-dark border border-primary bg-secondary cus_heading_login">
                        Welcome to <br></br>Wedding Match!
                    </h3>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mt-9">
                <div>
                    <h2>WHO ARE YOU?</h2>
                </div>
                <div className="d-flex flex-wrap m-5">
                    <button 
                        onClick={() => dispatch(setUserRole("superadmin"))}
                        className={`btn btn-primary text-dark text-hover-light font-weight-bold px-9 py-4 my-3 m-5`}
                    >
                        Super Admin
                    </button>
                    <button 
                        onClick={() => dispatch(setUserRole("user"))}
                        className={`btn btn-primary text-dark text-hover-light font-weight-bold px-9 py-4 my-3 m-5`}
                    >
                        User
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}

export default MainScreen;