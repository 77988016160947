import $ from "jquery";
import _ from "lodash";
import React from "react";
import swal from "sweetalert";
import Swal2 from "sweetalert2";
import * as Api from "./api";
import html2canvas from 'html2canvas';
import { message } from "antd";
import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import html2PDF from 'jspdf-html2canvas';

export const versionNumber = 'TR 1.1.25';
export function updateInputValueInState(
  stateVariable,
  event,
  _that,
  state,
  ReactSelectOBJ
) {
  if (ReactSelectOBJ != null && ReactSelectOBJ != undefined && ReactSelectOBJ) {
    if (event == undefined) {
      stateVariable[ReactSelectOBJ["NameProp"]] = [];
      state[ReactSelectOBJ["ValidationState"]] = false;
    } else {
      stateVariable[ReactSelectOBJ["NameProp"]] = event.map((obj, index) => {
        return obj.value;
      });
      state[ReactSelectOBJ["ValidationState"]] = true;
    }
  } else if (event.target.type == "checkbox") {
    stateVariable[event.target.name] = event.target.checked;
  } else {
    stateVariable[event.target.name] = event.target.value;
  }
  _that.setState(state);
}
export function withOutEventUpdateInputValueInState(
  stateVariable,
  _that,
  state,
  value,
  name,
  ReactSelectOBJ
) {
  if (ReactSelectOBJ != null && ReactSelectOBJ != undefined && ReactSelectOBJ) {

    //   if (event == undefined) {
    //     stateVariable[ReactSelectOBJ["NameProp"]] = [];
    //     state[ReactSelectOBJ["ValidationState"]] = false;
    //   } else {
    //     stateVariable[ReactSelectOBJ["NameProp"]] = event.map((obj, index) => {
    //       return obj.value;
    //     });
    //     state[ReactSelectOBJ["ValidationState"]] = true;
    //   }
    // } 
    // else if (event.target.type == "checkbox") {
    //   stateVariable[event.target.name] = event.target.checked;
    // }
  }
  stateVariable[name] = value;

  _that.setState(state);
}
export function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return "?" + keyValuePairs.join("&");
}

export function updateInputSelectValueInState(
  stateVariable,
  event,
  controlName,
  _that,
  state,
  ReactSelectOBJ
) {
  if (ReactSelectOBJ != null && ReactSelectOBJ != undefined && ReactSelectOBJ) {
    if (event == undefined) {
      stateVariable[ReactSelectOBJ["NameProp"]] = [];
      state[ReactSelectOBJ["ValidationState"]] = false;
    } else {
      stateVariable[ReactSelectOBJ["NameProp"]] = event.map((obj, index) => {
        return obj.value;
      });
      state[ReactSelectOBJ["ValidationState"]] = true;
    }
  } else {
    stateVariable[controlName] = event.value;
  }
  _that.setState(state);
}

export function updateEmailInputValueInState(
  stateVariable,
  event,
  _that,
  state,
  ReactSelectOBJ
) {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)) {
    UpdateCustomElementValidation(event, true);
  } else {
    UpdateCustomElementValidation(event, false);
  }
  stateVariable[event.target.name] = event.target.value;
  _that.setState(state);
}
export function getFormattedPhoneNum(input) {
  if (input == "" || input == null) {
    return "";
  }
  let output = "(";
  input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {

    if (g1.length) {
      output += g1;
      if (g1.length == 3) {
        output += ")";
        if (g2.length) {
          output += " " + g2;
          if (g2.length == 3) {
            output += " - ";
            if (g3.length) {
              output += g3;
            }
          }
        }
      }
    }
  }
  );
  return output;
}
export function showSuccessAlert(msg, type, title) {
  swal({
    title: title,
    text: msg,
    icon: type,
  });
}

export function showSuccessAlertAutoClose(msg, type, title, timer) {
  swal({
    title: title,
    text: msg,
    icon: type,
    timer: timer,
    buttons: false,
  });
}

export function showConfirmation(
  msg,
  title,
  confirmCallback,
  cancelCallback = null,
  obj,
  buttonText = "OK"
) {
  Swal2.fire({
    title: title ? title : "Are you sure?",
    text: msg ? msg : "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: buttonText,
  }).then((result) => {
    if (result.value) {
      if (_.isFunction(confirmCallback)) confirmCallback(obj);
    } else {
      if (_.isFunction(cancelCallback)) {
        if (cancelCallback != null) {
        }
        cancelCallback(obj);
      }
    }
  });
}

export function GetBaseUrl() {
  var getUrl = window.location;
  var baseUrl = getUrl.protocol + "//" + getUrl.host;
  return baseUrl;
}

export function GetDateTimeString(dateString) {
  var date = new Date(dateString);
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.toLocaleTimeString()
  );
}
export function GetDateString(dateString) {
  var date = new Date(dateString);
  return (
    minTwoDigit(date.getFullYear()) +
    "-" +
    minTwoDigit(date.getMonth() + 1) +
    "-" +
    minTwoDigit(date.getDate())
  );
}

function minTwoDigit(input) {
  if ((input + "").length == 1) return "0" + input;
  return input;
}

export const BusEntityDetailType = {
  DescriptionHtml: "DescriptionHtml",
  LogoPath: "LogoPath",
};
export function getCurrentDate(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
}
export function UpdateCustomElementValidation(event, isValid) {
  if (isValid) {
    event.currentTarget.nextElementSibling.style.display = "";
    event.target.setCustomValidity("");
  } else {
    event.currentTarget.nextElementSibling.style.display = "block";
    event.target.setCustomValidity("Invalid field.");
  }
}

export function prependZeroIfLessThanTen(item) {
  if (item.toString().length === 1) {
    return "0" + item.toString();
  } else {
    return item;
  }
}

export function LoadDataTable(tableId) {
  if ($.fn.dataTable.isDataTable("#" + tableId)) {
    $("#" + tableId).DataTable = $("#" + tableId).DataTable();
  } else {
    $("#" + tableId).DataTable({
      responsive: true,
      pageLength: 25,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, "All"],
      ],
    });
  }
}


export function replaceNullWithString(value, replaceValue) {
  if (value == null) {
    value = replaceValue != null ? replaceValue : "";
  }
  return value;
}
export function replaceNullOrEmptyWithString(targetString, replaceValue) {
  targetString = targetString?.trim();
  if (targetString == null || targetString == "") {
    targetString = replaceValue;
  }
  return targetString;
}

export function getInputStringDate(date) {

  //date= new Date();
  let dt = "";
  try {
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dt = date.getFullYear() + "-" + month + "-" + _date;
  } catch (ex) {
  }
  return dt;
}
export function getInputStringDateUsingMoment(date) {
  //date= new Date();
  let dt = "";
  try {
    const month =
      date._d.getMonth() + 1 < 10
        ? "0" + (date._d.getMonth() + 1)
        : date._d.getMonth() + 1;
    const _date = date._d.getDate() < 10 ? "0" + date._d.getDate() : date._d.getDate();
    dt = date._d.getFullYear() + "-" + month + "-" + _date;
  } catch (ex) {
  }
  return dt;
}
export function getInputStringDateFormat(date) {

  //date= new Date();
  let dt = "";
  try {
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dt = month + "/" + _date + "/" + date.getFullYear();
  } catch (ex) {
  }
  return dt;
}
export function getInputStringDate2(date) {

  date = new Date();
  let dt = "";
  try {
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dt = date.getFullYear() + "-" + month + "-" + _date;
  } catch (ex) {
  }
  return dt;
}

export function getDifferenceInDays(date1, date2) {

  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const end = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;

}


// Download the file
export const download = async (imageUrl) => {
  const originalImage = imageUrl;
  const image = await fetch(originalImage);

  //Split image name
  const nameSplit = originalImage.split("/");
  const duplicateName = nameSplit.pop();

  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)
  const link = document.createElement('a')
  link.href = imageURL;
  link.download = "" + duplicateName + "";
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
};

// DOWNLOAD FILE AS PDF

export const downloadFileAsPDF = async (url) => {
  saveAs(
    url,
    "file.pdf"
  );
}

// Download React JSX part as Image
export const downloadReactJSXPartAsImage = async (printRef) => {
  const element = printRef.current;
  const canvas = await html2canvas(element);

  const data = canvas.toDataURL('image/jpg');
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = data;
    link.download = 'image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(data);
  }
};

// Download React JSX part as PDF
export const downloadReactJSXPartAsPDF = async (printRef) => {

  const element = printRef.current
  const element2 = document.getElementById("print-div")

  html2PDF(element, {
    jsPDF: {
      format: 'a4',
    },
    imageType: 'image/jpeg',
    output: '.report.pdf'
  });


  // const canvas = await html2canvas(element);
  // const data = canvas.toDataURL('image/png');
  // const doc = new jsPDF('p', 'mm')
  // const width = doc.internal.pageSize.getWidth();
  // const height = doc.internal.pageSize.getHeight();
  // if(window.screen.width > 300 && window.screen.width < 600){
  // doc.addImage(data, "png", 60, 0, 90, 220)
  // doc.save('sample-file.pdf');
  // }else{
  // doc.addImage(data, "png", 0, 0, width, height)
  // doc.save('sample-file.pdf');
  // }

  // const link = document.createElement('a');

  // if (typeof link.download === 'string') {
  //   link.href = doc;
  //   link.download = 'file.pdf';

  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // } else {
  //   window.open(data);
  // }
};

// Toast

export const toast = (status, messageTxt, duration) => {
  if (status === "success") {
    message.success(messageTxt, duration);
  } else if (status === "error") {
    message.error(messageTxt, duration);
  } else if (status === "info") {
    message.info(messageTxt, duration);
  } else if (status === "loading") {
    message.loading(messageTxt, duration);
  } else if (status === "warning") {
    message.warning(messageTxt, duration);
  } else if (status === "warn") {
    message.warn(messageTxt, duration);
  }
}

// //Here is the code for converting "image source" (url) to "Base64".***

// //https://cors-anywhere.herokuapp.com/

// export const toDataURL = url => fetch(`https://cors-anywhere.herokuapp.com/${url}`)
//       .then(response => response.blob())
//       .then(blob => new Promise((resolve, reject) => {
//       const reader = new FileReader()
//       reader.onloadend = () => resolve(reader.result)
//       reader.onerror = reject
//       reader.readAsDataURL(blob)
//      }))


//Here is code for converting "Base64" to javascript "File Object".***

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}


// check if two objects are equal

export function checkObjEqual(obj1, obj2) {
  for (let key in obj1) {
    if (!(key in obj2)) return false;
    if (obj1[key] !== obj2[key]) return false;
  }
  return true;
}

export function isEven(num) {
  return num % 2 === 0;
}

export function isOdd(num) {
  return num % 2 !== 0;
}
export function isLast(arr, index){
  return index === arr.length - 1;
}


// Prevent Input field to accept minus value

export const preventMinus = (e) => {
  if (e.key === '-') {
      e.preventDefault();
  }
  if (e.key.toLowerCase() === 'e') {
    e.preventDefault();
  }
};

// Prevent Input field to accept pasted minus value

export const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData('text'));

  if (pastedData < 0) {
      e.preventDefault();
  }
};